<template>
	<div>
		<ContentHeader title="Perawatan" url="/perawatan" subTitle="Perawatan Pasien" />
		<LoadingScreen v-if="loading_screen" />
		<div class="content-header">
			<div class="container-fluid">
				<div class="row justify-content-center">
					<div class="col">
						<div class="card">
							<div class="card-header">
				               <h3 class="card-title"><div>Data Pasien &amp; Dokter Pemeriksa</div></h3>
				                <div class="card-tools">
				                  <button type="button" class="btn btn-tool" data-card-widget="collapse" title="Collapse">
				                    <i class="fas fa-minus"></i>
				                  </button>
				                </div>
				              </div>
		            		<div class="card-body ">
		            			<div class="row px-lg-2">
		            				<div class="col">
		            					<div class="form-group">
						                  <label for="nama">Nama</label>
						                  <input type="text" class="form-control" id="nama" name="nama" disabled="" v-model="rekam_medis.nama_pasien">
						                </div>
						                <div class="form-group">
						                  <label for="jenis_kelamin">Jenis Kelamin</label>
						                  <input type="text" class="form-control" id="jenis_kelamin" name="jenis_kelamin" disabled="" v-model="jenis_kelamin_pada_ui">
						                </div>
		            				</div>
		            				<div class="col">
		            					<div class="form-group">
						                  <label for="umur">Umur</label>
						                  <input type="text" class="form-control" id="umur" name="umur" disabled="" v-model="umur">
						                </div>
										<div class="form-group">
						                  <label for="kunjungan_berikutnya">Kunjungan Berikutnya</label>
						                  <input type="text" class="form-control" id="kunjungan_berikutnya" name="kunjungan_berikutnya" disabled="" v-model="kunjungan_berikutnya">
						                </div>
		            				</div>
		            			</div>
								<div class="row px-lg-4 w-100">
									<el-collapse v-model="alergiActiveCollapse" class="w-100">
										<el-collapse-item title="Alergi" name="1">
											<template #title>
												<strong class="text-danger">Alergi</strong>
											</template>	
											<div class="text-danger">
												{{ alergi }}
											</div>
										</el-collapse-item>
									</el-collapse>
								</div>
								<hr>
								<div class="row px-lg-2">
									<div class="col">
										<div class="form-group">
						                  <label for="dokter">Poli</label>
						                  <select class="custom-select form-control" v-model="rekam_medis.poli_id" >
						                  	<option v-for="(row, index) in dataPoli" :key="index" :value="row.poli.id">{{ row.nama }}</option>
						                  </select>
						                </div>
									</div>
									<div class="col">
										<div class="form-group">
						                  <label for="dokter">Layanan</label>
						                  <select class="custom-select form-control" v-model="rekam_medis.layanan_id" >
						                  	<option v-for="(row, index) in dataLayanan" :key="index" :value="row.id">{{ row.nama }}</option>
						                  </select>
						                </div>
									</div>
									<div class="col">
										<div class="form-group">
						                  <label for="dokter">Dokter</label>
						                  <select class="custom-select form-control" v-model="rekam_medis.dokter_id">
						                  	<option v-for="(row, index) in dataDokter" :key="index" :value="row.id">{{ row.nama_lengkap }}</option>
						                  </select>
						                </div>
									</div>
								</div>
		            		</div>
						</div>
					</div>
				</div>
				<div class="row justify-content-center">
					<div class="col">
						<div class="card">
							<div class="card-header row">
								<h3 class="card-title">Input Data Pemeriksaan</h3>
							</div>
							<div class="card-body">
								<form @submit.prevent="prosesRekamMedis">
									<div class="px-lg-2 ">
									<div class="form-group">
										<label for="nomor_rekam_medis">No Rekam Medis</label>
										<input type="text" class="form-control" id="nomor_rekam_medis" name="nomor_rekam_medis" disabled="" v-model="rekam_medis.nomor_rekam_medis">
									</div>
									<div class="form-group" v-if="namaPoliSelected?.slice(0, 3).toLowerCase() == 'ugd'">
										<label for="pemeriksaan_umum">Pemeriksaan Umum</label>
										<div class="row">
											<div class="col">
												<p>Keadaan Datang <span class="text-danger">*</span></p>
												<select class="form-control" name="keadaan_datang" id="keadaan_datang" required="" v-model="rekam_medis.keadaan_datang">
													<option value="penyakit">Penyakit</option>
													<option value="kecelakaan_lalu_lintas">Kecelakaan Lalu Lintas</option>
													<option value="kecelakaan_kerja">Kecelakaan Kerja</option>
													<option value="lain_lain">Lain-lain</option>
												</select>
											</div>
											<div class="col">
												<p>Dirujuk <span class="text-danger">*</span></p>
												<select class="form-control" name="dirujuk" id="dirujuk" required="" v-model="rekam_medis.dirujuk">
													<option value="1">Ya</option>
													<option value="0">Tidak</option>
												</select>
											</div>
											<div class="col">
												<p>Kondisi <span class="text-danger">*</span></p>
												<select class="form-control" name="kondisi" id="kondisi" required="" v-model="rekam_medis.kondisi">
													<option value="gawat_darurat">Gawat Darurat</option>
													<option value="darurat">Darurat</option>
													<option value="tidak_darurat">Tidak Darurat</option>
													<option value="meninggal">Meninggal</option>
												</select>
											</div>
											<div class="col">
												<p>Keterangan</p>
												<input type="text" class="form-control" placeholder="Masukkan Keterangan" v-model="rekam_medis.keterangan_pemeriksaan_umum">
											</div>
										</div>
									</div>

									<div class="form-group">
										<label for="anamnesa">Anamnesa <span class="text-danger"><small>* KELUHAH UTAMA, RIWAYAT PENYAKIT SEKARANG, RIWAYAT PENYAKIT DAHULU, RIWAYAT PENYAKIT KELUARGA</small></span></label>
										<textarea class="form-control" style="height: 100px;" id="anamnesa" required="" name="anamnesa" v-model="rekam_medis.anamnesa" placeholder="Masukkan Anamnesa"></textarea>
									</div>
									<div class="form-group" style="margin-top: 20px;" v-if="namaPoliSelected?.toLowerCase() != 'kecantikan'">
										<label>Pemeriksaan Fisik</label>
										<div class="row">
											<div class="col-6 col-md-3">
												<p>Tinggi Badan<span class="text-danger">*</span></p>
												<div class="input-group mb-3">
													<input type="number" class="form-control" name="tinggi_badan" required="" v-model="rekam_medis.tinggi_badan">
													<div class="input-group-prepend">
													<div class="btn btn-secondary">Cm</div>
													</div>
												</div>
											</div>
											<div class="col-6 col-md-3">
												<p>Berat Badan<span class="text-danger">*</span></p>
												<div class="input-group mb-3">
													<input type="number" class="form-control" name="berat_badan" required="" v-model="rekam_medis.berat_badan">
													<div class="input-group-prepend">
													<div class="btn btn-secondary">Kg</div>
													</div>
												</div>
											</div>
											<div class="col-12 col-md-6">
												<p>Keadaan Umum<span class="text-danger">*</span></p>
												<input type="text" class="form-control" name="keadaan_umum" v-model="rekam_medis.keadaan_umum">
											</div>
										</div>
										<div class="row">
											<div class="col">
												<p>Catatan</p>
												<textarea class="form-control" style="height: 100px;" id="catatan_pemeriksaan_fisik" name="catatan_pemeriksaan_fisik" v-model="rekam_medis.catatan_pemeriksaan_fisik"></textarea>
											</div>
										</div>
									</div>
									<div class="form-group mt-3">
										<label>Pemeriksaan Tanda Vital</label>
										<div class="row">
											<div class="col-6 col-md-3" v-if="namaPoliSelected?.toLowerCase() != 'kecantikan'">
												<p>Frekuensi Denyut Jantung<span class="text-danger">*</span></p>
												<div class="input-group mb-3">
													<input type="number" class="form-control" name="frekuensi_denyut_jantung" v-model="rekam_medis.frekuensi_denyut_jantung">
													<div class="input-group-prepend">
													<div class="btn btn-secondary">Per Menit</div>
													</div>
												</div>
											</div>
											<div class="col-6 col-md-3" v-if="namaPoliSelected?.toLowerCase() != 'kecantikan'">
												<p>Frekuensi Pernapasan<span class="text-danger">*</span></p>
												<div class="input-group mb-3">
													<input type="number" class="form-control" name="frekuensi_pernapasan" v-model="rekam_medis.frekuensi_pernapasan">
													<div class="input-group-prepend">
													<div class="btn btn-secondary">Per Menit</div>
													</div>
												</div>
											</div>
											<div class="col-6 col-md-3">
												<p>Suhu Tubuh<span class="text-danger">*</span></p>
												<div class="input-group mb-3">
													<input type="number" class="form-control" name="suhu_tubuh" v-model="rekam_medis.suhu_tubuh">
													<div class="input-group-prepend">
													<div class="btn btn-secondary">°Celcius</div>
													</div>
												</div>
											</div>
											<div class="col-6 col-md-3" v-if="namaPoliSelected?.toLowerCase() != 'kecantikan'">
												<p>Tekanan Darah<span class="text-danger">*</span></p>
												<div class="input-group mb-3">
													<input type="text" class="form-control" name="tekanan_darah" v-model="rekam_medis.tekanan_darah">
													<div class="input-group-prepend">
													<div class="btn btn-secondary">mmHg</div>
													</div>
												</div>
											</div>
										</div>
										<div class="row" v-if="namaPoliSelected?.toLowerCase() != 'kecantikan'">
											<div class="col-6 col-md-3">
												<p>Saturasi Oksigen <span class="text-danger">*</span></p>
												<div class="input-group mb-3">
													<input type="number" class="form-control" name="saturasi_oksigen" v-model="rekam_medis.saturasi_oksigen">
													<div class="input-group-prepend">
													<div class="btn btn-secondary">Per Menit</div>
													</div>
												</div>
											</div>
										</div>
									</div>

									<div v-if="namaPoliSelected?.slice(0, 3).toLowerCase() == 'ugd'">
										<div class="form-group" style="margin-top: 20px;">
											<label for="status_neurologis">Status Neurologis</label>
											<div class="row mb-3">
												<div class="col">
													<p>Kesadaran<span class="text-danger">*</span></p>
													<input type="text" required="" v-model="rekam_medis.kesadaran" class="form-control">
												</div>
											</div>
											<div class="row mb-3">
												<div class="col">
													<p>Kepala<span class="text-danger">*</span></p>
													<input type="text" required="" v-model="rekam_medis.kepala" class="form-control">
												</div>
												<div class="col">
													<p>Thorax<span class="text-danger">*</span></p>
													<input type="text" required="" v-model="rekam_medis.thorax" class="form-control">
												</div>
												<div class="col">
													<p>Abdomen<span class="text-danger">*</span></p>
													<input type="text" required="" v-model="rekam_medis.abdomen" class="form-control">
												</div>
												<div class="col">
													<p>Ekstremitas<span class="text-danger">*</span></p>
													<input type="text" required="" v-model="rekam_medis.ekstremitas" class="form-control">
												</div>
											</div>
										</div>
										<div class="form-group">
											<label>GCS</label>
											<div class="row mb-3">
												<div class="col">
													<p>Eye Movement<span class="text-danger">*</span></p>
													<input type="text" required="" v-model="rekam_medis.eye_movement" class="form-control">
												</div>
												<div class="col">
													<p>Refleks Motorik<span class="text-danger">*</span></p>
													<input type="text" required="" v-model="rekam_medis.refleks_motorik" class="form-control">
												</div>
												<div class="col">
													<p>Verbal<span class="text-danger">*</span></p>
													<input type="text" required="" v-model="rekam_medis.verbal" class="form-control">
												</div>
											</div>
										</div>
									</div>

									<div class="form-group">
										<div class="row">
											<div class="col-12 col-md-4">
												<label for="icdx">Diagnosa ICDX</label>
												<multiselect 
												v-model="icdx_select_value"
												deselect-label="Can't remove this value"
												track-by="diagnosa"
												label="diagnosa"
												placeholder=""
												:custom-label="customSelectIcdx"
												:options="icdx"
												:allow-empty="false"
												:loading="memuat_icdx"
												@update:model-value="tambahIcdxKeRekamMedis"
												>
												</multiselect>
											</div>
											<div class="col col-md-1"></div>
											<div class="col-12 col-md-7">
												<label class="mt-1">  </label>
												<table class="table table-bordered table-hover">
												<thead>
													<tr>
													<th scope="col" class="text-sm">NO</th>
													<th scope="col" class="text-sm">KODE</th>
													<th scope="col" class="text-sm">NAMA DIAGNOSA</th>
													<th scope="col" class="text-sm">DIAGNOSA UTAMA</th>
													<th scope="col" class="text-sm">ACTION</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(row, index) in icdxResult" :key="row.id">
														<td>{{ index+1 }}</td>
														<td >{{ row.kode }}</td>
														<td >{{ row.nama }}</td>
														<td class="text-center">
															<el-switch v-model="row.diagnosa_utama" inactive-value="0" active-value="1" @change="changeDiagnosaUtama(index)" />
														</td>
														<td><a class="btn btn-block btn-outline-danger btn-xs" @click="hapusDataIcdx(row.kode)" v-if="row.hapus">Hapus</a></td>
													</tr>
												</tbody>
												</table>
											</div>
										</div>
									</div>

									<!-- Form Khusus Kecantikan -->
									<div v-if="namaPoliSelected?.toLowerCase() == 'kecantikan'">
										<div class="form-group">
											<label for="kebutuhan_pasien">Kebutuhan Pasien<span class="text-danger">*</span></label>
											<div>
												<span>Riwayat pemeriksaan di Klinik/Salon</span><br>
												<el-switch class="my-1" size="large" active-text="Ya" inactive-text="Tidak" active-value="1" inactive-value="0" v-model="rekam_medis.toggle_kebutuhan_pasien" /><br>
											</div>
											<textarea class="form-control" style="height: 100px;" id="kebutuhan_pasien" name="kebutuhan_pasien" cols="3" v-if="rekam_medis.toggle_kebutuhan_pasien == '1'" v-model="rekam_medis.kebutuhan_pasien" placeholder="Masukkan Kebutuhan Pasien"></textarea>
										</div>
										<div>
											<label for="riwayat_medis">Riwayat Medis<span class="text-danger">*</span></label><br>
											<el-select v-model="rekam_medis.riwayat_medis" multiple placeholder="Pilih Riwayat Medis" style="width: 300px">
												<el-option v-for="(item, index) in riwayat_medis" :key="index" :label="item" :value="item"/>
											</el-select>
											<textarea class="form-control mt-2" style="height: 100px;" id="catatan_riwayat_pasien" name="catatan_riwayat_pasien" cols="3" v-model="rekam_medis.catatan_riwayat_pasien" placeholder="Masukkan Catatan Riwayat Medis"></textarea>
										</div>
										<div class="mt-4">
											<label for="riwayat_perawatan">Riwayat Perawatan<span class="text-danger">*</span></label><br>
											<el-select v-model="rekam_medis.riwayat_perawatan" multiple placeholder="Pilih Riwayat Perawatan" style="width: 300px">
												<el-option v-for="(item, index) in riwayat_perawatan" :key="index" :label="item" :value="item"/>
											</el-select>
											<textarea class="form-control mt-2" style="height: 100px;" id="catatan_riwayat_pemeriksaan" name="catatan_riwayat_pemeriksaan" cols="3" v-model="rekam_medis.catatan_riwayat_pemeriksaan" placeholder="Masukkan Catatan Riwayat Pemeriksa"></textarea>
										</div>
										<div class="mt-4">
											<label for="perawatan_sehari_hari">Perawatan Sehari Hari<span class="text-danger">*</span></label><br>
											<textarea class="form-control mt-2" style="height: 100px;" id="perawatan_sehari_hari" name="perawatan_sehari_hari" cols="3" v-model="rekam_medis.perawatan_sehari_hari" placeholder="Masukkan Perawatan Sehari Hari"></textarea>
										</div>
										<div class="mt-4">
											<label for="gaya_hidup">Gaya Hidup<span class="text-danger">*</span></label><br>
											<el-select v-model="rekam_medis.gaya_hidup" multiple placeholder="Pilih Gaya Hidup" style="width: 300px">
												<el-option v-for="(item, index) in gaya_hidup" :key="index" :label="item" :value="item"/>
											</el-select>
											<textarea class="form-control mt-2" style="height: 100px;" id="catatan_gaya_hidup" name="catatan_gaya_hidup" cols="3" v-model="rekam_medis.catatan_gaya_hidup" placeholder="Masukkan Catatan Riwayat Pemeriksa"></textarea>
										</div>
										<div class="form-group mt-4">
											<label for="analisis_kulit">Analisis Kulit</label>
											<div class="row mb-3">
												<div class="col">
													<p>Ukuran Pori-pori<span class="text-danger">*</span></p>
													<select name="ukuran_pori_pori" class="form-control" required="" id="ukuran_pori_pori" v-model="rekam_medis.ukuran_pori_pori">
														<option :value="null">-- Pilih Ukuran Pori-pori --</option>
														<option value="kecil">Kecil</option>
														<option value="sedang">Sedang</option>
														<option value="Besar">Besar</option>
													</select>
												</div>
												<div class="col">
													<p>Texture/Permukaan<span class="text-danger">*</span></p>
													<select name="permukaan" class="form-control" required="" id="permukaan" v-model="rekam_medis.permukaan">
														<option :value="null">-- Pilih Peermukaan --</option>
														<option value="normal 25%">Normal 20%</option>
														<option value="berminyak 40%">Berminyak 40%</option>
														<option value="dry">Dry</option>
													</select>
												</div>
												<div class="col">
													<p>Ketebalan<span class="text-danger">*</span></p>
													<select name="ketebalan" class="form-control" required="" id="ketebalan" v-model="rekam_medis.ketebalan">
														<option :value="null">-- Pilih Ketebalan --</option>
														<option value="tipis">Tipis</option>
														<option value="sedang">Sedang</option>
														<option value="tebal">Tebal</option>
													</select>
												</div>
												<div class="col">
													<p>Tonus Otot<span class="text-danger">*</span></p>
													<select name="tonus_otot" class="form-control" required="" id="tonus_otot" v-model="rekam_medis.tonus_otot">
														<option :value="null">-- Pilih Tonus Otot --</option>
														<option value="buruk">Buruk</option>
														<option value="sedang">Sedang</option>
														<option value="baik">Baik</option>
													</select>
												</div>
												<div class="col">
													<p>Elastisitas<span class="text-danger">*</span></p>
													<select name="elastisitas" class="form-control" required="" id="elastisitas" v-model="rekam_medis.elastisitas">
														<option :value="null">-- Pilih Elastisitas --</option>
														<option value="buruk">Buruk</option>
														<option value="sedang">Sedang</option>
														<option value="baik">Baik</option>
													</select>
												</div>
											</div>
											<div class="row mb-3">
												<div class="col">
													<p>Kelembapan<span class="text-danger">*</span></p>
													<select name="kelembapan" class="form-control" required="" id="kelembapan" v-model="rekam_medis.kelembapan">
														<option :value="null">-- Pilih Kelembapan --</option>
														<option value="buruk">Buruk</option>
														<option value="sedang">Sedang</option>
														<option value="baik">Baik</option>
													</select>
												</div>
												<div class="col">
													<p>Sensitivitas<span class="text-danger">*</span></p>
													<select name="sensitivitas" class="form-control" required="" id="sensitivitas" v-model="rekam_medis.sensitivitas">
														<option :value="null">-- Pilih Sensitivitas --</option>
														<option value="normal">Normal</option>
														<option value="sensitif">Sensitif</option>
														<option value="Hipersensitif">Hipersensitif</option>
													</select>
												</div>
												<div class="col">
													<p>Sirkulasi Darah<span class="text-danger">*</span></p>
													<select name="sirkulasi_darah" class="form-control" required="" id="sirkulasi_darah" v-model="rekam_medis.sirkulasi_darah">
														<option :value="null">-- Pilih Sirkulasi Darah --</option>
														<option value="buruk">Buruk</option>
														<option value="sedang">Sedang</option>
														<option value="baik">Baik</option>
													</select>
												</div>
												<div class="col">
													<p>Kerutan<span class="text-danger">*</span></p>
													<select name="kerutan" class="form-control" required="" id="kerutan" v-model="rekam_medis.kerutan">
														<option :value="null">-- Pilih Kerutan --</option>
														<option value="halus">Halus</option>
														<option value="sedang">Sedang</option>
														<option value="dalam">Dalam</option>
													</select>
												</div>
												<div class="col">
													<p>Jenis Kulit<span class="text-danger">*</span></p>
													<select name="jenis_kulit" class="form-control" required="" id="jenis_kulit" v-model="rekam_medis.jenis_kulit">
														<option :value="null">-- Pilih Jenis Kulit --</option>
														<option value="normal">Normal</option>
														<option value="kombinasi">Kombinasi</option>
														<option value="Berminyak">Berminyak</option>
														<option value="kering">Kering</option>
														<option value="sensitif">Sensitif</option>
													</select>
												</div>
											</div>
										</div>
										
										<div class="form-group mt-4">
											<label for="kelainan_kulit">Kelainan Kulit</label><br>
											<div class="row ml-1">
												<div class="col-4 border d-flex justify-content-center">
													<img src="@/assets/foto-wajah.png" style="height: 200px;width: auto;" alt="">
												</div>
												<div class="col-8">
													<el-select v-model="rekam_medis.kelainan_kulit" multiple placeholder="Pilih Kelainan Kulit" style="width: 100%;">
														<el-option v-for="(item, index) in kelainan_kulit" :key="index" :label="item" :value="item"/>
													</el-select>
												</div>
											</div>
										</div>
										<div class="form-group mt-4">
											<label for="rencana_perawatan">Rencana Perawatan</label><br>
											<span>Perawatan</span>
											<input class="form-control mb-2" type="text" v-model="rekam_medis.perawatan" placeholder="Masukkan Perawatan">
											<span>Cleanser</span>
											<input class="form-control mb-2" type="text" v-model="rekam_medis.cleanser" placeholder="Masukkan Cleanser">
											<span>Scrub</span>
											<input class="form-control mb-2" type="text" v-model="rekam_medis.scrub" placeholder="Masukkan Scrub">
											<span>Massage Cream</span>
											<input class="form-control mb-2" type="text" v-model="rekam_medis.cream" placeholder="Masukkan Massage Cream">
											<span>Masker</span>
											<input class="form-control mb-2" type="text" v-model="rekam_medis.masker" placeholder="Masukkan Masker">
											<span>Alat Listrik</span>
											<input class="form-control mb-2" type="text" v-model="rekam_medis.alat_listrik" placeholder="Masukkan Alat Listrik">
										</div>
										<div class="form-group">
											<label for="perawatan_dirumah">Perawatan Dirumah</label><br>
											<span>Produk</span>
											<select class="form-control mb-2" v-model="rekam_medis.perawatan_dirumah">
												<option value="cleansing milk">Cleansing Milk</option>
												<option value="facial cleanser">Facial Cleanser</option>
												<option value="toner">toner</option>
												<option value="moisturizer">Moisturizer</option>
												<option value="sunscreen">Sunscreen</option>
												<option value="serum">Serum</option>
												<option value="night cream">Night Cream</option>
											</select>
											<span>Saran</span>
											<textarea class="form-control" style="height: 100px;" v-model="rekam_medis.saran"></textarea>
										</div>
									</div>
									<!-- Form Khusus Kecantikan -->

									<div class="form-group" v-if="namaPoliSelected?.toLowerCase() != 'kecantikan'">
										<label for="pemeriksaan_penunjang">Pemeriksaan Penunjang</label>
										<textarea class="form-control" style="height: 100px;" id="pemeriksaan_penunjang" name="pemeriksaan_penunjang"  v-model="rekam_medis.pemeriksaan_penunjang"></textarea>
									</div>
									<div class="form-group" v-if="namaPoliSelected?.toLowerCase() != 'kecantikan'">
										<p for="diagnosa">Catatan Diagnosa </p>
										<textarea class="form-control" style="height: 100px;" id="diagnosa" name="diagnosa" v-model="rekam_medis.diagnosa"></textarea>
									</div>
									<div class="form-group" v-if="namaPoliSelected?.toLowerCase() != 'kecantikan'">
										<div class="row">
											<div class="col-4">
												<label for="tindak_lanjut">Tindak Lanjut</label>
												<select name="tindak_lanjut" class="form-control"  id="tindak_lanjut" v-model="rekam_medis.tindak_lanjut">
													<option :value="null">-- Pilih Tindak Lanjut --</option>
													<option value="Pulang dengan keadaan">Pulang dengan keadaan</option>
													<option value="Dirujuk ke">Dirujuk ke</option>
													<option value="Rawat Sementara">Rawat Sementara</option>
													<option value="Meninggal jam">Meninggal jam</option>
													<option value="DOA">DOA</option>
												</select>
											</div>
											<div class="col-8" v-if="rekam_medis.tindak_lanjut == 'Pulang dengan keadaan' || rekam_medis.tindak_lanjut == 'Dirujuk ke' || rekam_medis.tindak_lanjut == 'Meninggal jam'">
												<label for=""></label>
												<textarea name="tindak_lanjut_detail" id="tindak_lanjut_detail" required="" v-model="rekam_medis.tindak_lanjut_detail" class="form-control" cols="30" rows="5"></textarea>
											</div>
										</div>
									</div>
									<div class="form-group mt-4">
										<div class="row">
											<div class="col-8">
												<label for="resep_obat">Resep Obat</label>
												<div class="d-flex">
													<multiselect style="width: 300px;" 
													v-model="obat_select_value"
													deselect-label="Can't remove this value"
													track-by="nama"
													label="nama"
													value="index"
													placeholder=""
													class="mr-2"
													:loading="loading_obat"
													:options="data_resep_dan_obat"
													:allow-empty="false"
													@update:model-value="tambahResepKeRekamMedis"
													>
													</multiselect>
													<select name="type_obat" id="type_obat" class="form-control" v-model="type_obat" @change="getObat()" style="width: 100px;">
														<option value="Umum">Umum</option>
														<option value="BPJS">BPJS</option>
													</select>

												</div>
												<p class="text-center text-sm text-danger" v-if="cek_data_resep">Stok Obat Kosong</p>
											</div>
											<div class="col-4 d-flex align-items-center mt-4">
												<el-input type="text" placeholder="Masukkan Signa Baru" v-model="newSigna"></el-input>
												<div class="input-group-prepend">
													<div class="btn btn-secondary fas fa-plus-square fa-2x" style="cursor: pointer;" @click="addNewSigna()"></div>
												</div>
											</div>
										</div>
									</div>

									<div class="row">
										<div class="col-12">
											<table class="table table-bordered table-hover">
											<thead>
												<tr>
												<th scope="col" class="text-sm">NO</th>
												<th scope="col" class="text-sm">KODE</th>
												<th scope="col" class="text-sm">NAMA RESEP</th>
												<th scope="col" class="text-sm">TIPE</th>
												<th scope="col" class="text-sm">STOK</th>
												<th scope="col" class="text-sm">JUMLAH</th>
												<th scope="col" class="text-sm">SIGNA</th>
												<th scope="col" class="text-sm">ACTION</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(row, index) in resepResult" :key="row.id">
													<td>{{ index+1 }}</td>
													<td >{{ row.kode }}</td>
													<td >{{ row.nama }}</td>
													<td >{{ row.type }}</td>
													<td >{{ row.stok }}</td>
													<td style="width: 100px;">
														<input class="form-control" type="number" step="any" v-model="row.jumlah" name="jumlah">
													</td>
													<td class="d-flex align-items-center">
														<el-select v-model="row.signa" filterable placeholder="Pilih Signa">
															<el-option value="" selected>- Signa -</el-option>
															<el-option v-for="(row, index) in signa" :key="index" :value="row">{{row}}</el-option>
														</el-select>
													</td>
													<td><a class="btn btn-block btn-outline-danger btn-xs" @click="hapusDataResep(row.id)" v-if="row.hapus">Hapus</a></td>
												</tr>
											</tbody>
											</table>
										</div>
									</div>

									<div class="form-group mt-4">
										<div class="row">
											<div class="col-12 col-md-3">
												<label for="tindakan">Tindakan</label>
												<multiselect style="min-width: 100px;" 
												v-model="tindakan_select_value"
												deselect-label="Can't remove this value"
												track-by="nama"
												label="nama"
												value="index"
												placeholder=""
												:options="tindakan"
												:allow-empty="false"
												@update:model-value="tambahTindakanKeRekamMedis"
												>
												</multiselect>
												<p class="text-center text-sm text-danger" v-if="cek_data_tindakan">Belum Ada Data Tindakan</p>
											</div>
											<div class="col col-md-1"></div>
											<div class="col-12 col-md-8">
												<label class="mt-1">  </label>
												<table class="table table-bordered table-hover">
												<thead>
													<tr>
													<th scope="col" class="text-sm">NO</th>
													<th scope="col" class="text-sm">KODE</th>
													<th scope="col" class="text-sm">NAMA TINDAKAN</th>
													<th scope="col" class="text-sm">LAMA TINDAKAN</th>
													<th scope="col" class="text-sm">JUMLAH</th>
													<th scope="col" class="text-sm">PERAWAT</th>
													<th scope="col" class="text-sm">ACTION</th>
													</tr>
												</thead>
												<tbody>
													<tr v-for="(row, index) in tindakanResult" :key="row.id">
														<td>{{ index+1 }}</td>
														<td >{{ row.kode }}</td>
														<td >{{ row.nama }}</td>
														<td >{{ row.lama_tindakan }} menit</td>
														<td style="width: 100px;">
															<input class="form-control" type="number" min="1" v-model="row.jumlah" name="jumlah">
														</td>
														<td>
															<button v-if="row.beautician.length == 0" type="button" class="btn btn-block btn-outline-primary btn-xs" data-toggle="modal" data-target="#exampleModal" @click="id_tindakan_ke_beautician = index">
																Tambah
															</button>
															<button v-else type="button" class="btn btn-block btn-outline-primary btn-xs" data-toggle="modal" data-target="#lihatbeautician" @click="lihatBeautician(index)">
																Lihat
															</button>
														</td>
														<td><a class="btn btn-block btn-outline-danger btn-xs" @click="hapusDataTindakan(row.id)" v-if="row.hapus">Hapus</a></td>
													</tr>
												</tbody>
												</table>
												<p class="text-danger text-sm text-center" v-if="cek_beautician">Data Perawat Masih Kosong</p>
											</div>
										</div>
									</div>
									<div class="form-group" v-if="namaPoliSelected?.toLowerCase() == 'kecantikan'">
										<label for="">Foto Before After</label>
										<div class="d-flex">
											<div>
												<p>Foto Before & After</p>
												<input ref="after_foto_dom" type="file" class="mt-n2" @change="inputImage">
											</div>
										</div>
										<div class="d-flex align-items-center justify-content-between">
											<div>
												<img v-if="after_foto != null" :src="after_foto" style="width: auto;max-height: 400px;">
											</div>
										</div>
									</div>
									<div class="form-group" >
										<label for="">Upload Informed Concent</label><br>
										<input ref="informed_concent_dom" type="file" class="mt-2" @change="inputInformedConcent">
									</div>
									
									<button type="submit" class="btn btn-primary d-flex align-items-center mt-5">
										<div>Submit</div>	
										<div class="spinner-border spinner-border-sm ml-2" v-if="loading" role="status">
											<span class="sr-only">Loading...</span>
										</div>
									</button>

									</div>
									<!-- /.card-body -->					               
								</form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<!-- Modal -->
		<!-- MODAL TAMBAH BEAUTICIAN -->
		<div class="modal fade bd-example-modal-lg" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
		  <div class="modal-dialog modal-lg" role="document">
		    <div class="modal-content">
		      <div class="modal-header">
		        <h5 class="modal-title" id="exampleModalLabel">Tambah Perawat</h5>
		        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
		          <span aria-hidden="true">&times;</span>
		        </button>
		      </div>
		      <div class="modal-body">
		        <div class="form-group">
	              	<div class="row">
	              		<div class="col-12 col-md-4">
	              			<label for="resep_obat">Perawat</label>
		                  	<select class="custom-select form-control" id="resep_obat" name="resep_obat" v-model="beauticianModel" @change="tambahbeauticianKeRekamMedis(beauticianModel)">
								<option v-for="(row, index) in beautician" :key="row.id" v-bind:value="index">{{ row.nama_lengkap }}</option>
							</select>
	              		</div>
	              		<div class="col col-md-1"></div>
	              		<div class="col-12 col-md-12">
	              			<label class="mt-1">  </label>
	              			<table class="table table-bordered table-hover">
							  <thead>
							    <tr>
							      <th class="text-sm">NO</th>
							      <th scope="col" class="text-sm">NAMA</th>
							      <th scope="col" class="text-sm">ACTION</th>
							    </tr>
							  </thead>
							  <tbody>
							    <tr v-for="(row, index) in beauticianResult" :key="row.id">
							    	<td>{{ index + 1 }}</td>
							    	<td>{{ row.nama_lengkap }}</td>
							    	<td><a class="btn btn-block btn-outline-danger btn-xs" @click="hapusDataBeautician(row.id)">Hapus</a></td>
							    </tr>
							  </tbody>
							</table>
	              		</div>
	              	</div>
	              </div>
		      </div>
		      <div class="modal-footer">
		        <button type="button" class="btn btn-primary" data-dismiss="modal" @click="resetInputBeautician()">Selesai</button>
		      </div>
		    </div>
		  </div>
		</div>

		<!-- MODAL LIHAT BEAUTICIAN -->
		<div class="modal fade bd-example-modal-lg" id="lihatbeautician" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel" aria-hidden="true">
		  <div class="modal-dialog modal-lg" role="document">
		    <div class="modal-content">
		      <div class="modal-header">
		        <h5 class="modal-title">Perawat</h5>
		        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
		          <span aria-hidden="true">&times;</span>
		        </button>
		      </div>
		      <div class="modal-body">
		        <div class="form-group">
	              	<div class="row">
	              		<div class="col col-md-1"></div>
	              		<div class="col-12 col-md-12">
	              			<label class="mt-1">  </label>
	              			<table class="table table-bordered table-hover">
							  <thead>
							    <tr>
							      <th class="text-sm">NO</th>
							      <th scope="col" class="text-sm">NAMA</th>
							    </tr>
							  </thead>
							  <tbody>
							    <tr v-for="(row, index) in beauticianDetail" :key="row.id">
							    	<td>{{ index + 1 }}</td>
							    	<td>{{ row.nama_lengkap }}</td>
							    </tr>
							  </tbody>
							</table>
	              		</div>
	              	</div>
	              </div>
		      </div>
		      <div class="modal-footer">
		        <button type="button" class="btn btn-primary" data-dismiss="modal" @click="resetInputBeautician()">Selesai</button>
		      </div>
		    </div>
		  </div>
		</div>
	</div>
</template>

<script setup>
	import ContentHeader from '@/components/ContentHeader2'
	import LoadingScreen from '@/components/LoadingScreen'
	import Multiselect from '@suadelabs/vue3-multiselect'
	import { ElMessage } from 'element-plus'
	import { debounce as _debounce } from "lodash"

	import { useRoute } from 'vue-router'
	import axios from 'axios'
	import { onMounted, ref, reactive, computed, watch } from 'vue'
	import store from '@/store'
	import Swal from 'sweetalert2'
	import router from '@/router'
	import get_age  from '@/umur.js'
	import * as dayjs from 'dayjs'

	const user = computed(() => store.getters['auth/user'])
	const cabang = computed(() => store.getters['auth/cabang'])

	const route = useRoute()
	const rekam_medis_id = route.params.id
	// Data Pasien
	const jenis_kelamin = ref()
	const jenis_kelamin_pada_ui = ref()
	const umur = ref()
	const loading = ref(false)
	const loading_screen = ref(true)
	const data_resep_dan_obat = ref([])
	const cek_beautician = ref(false)
	const cek_data_resep = ref(false)
	const cek_data_tindakan = ref(false)
	const obat_select_value = ref('')
	const tindakan_select_value = ref('')
	const icdx_select_value = ref('')
	const alergi = ref()
	const alergiActiveCollapse = ref(['1'])
	const type_obat = ref('Umum')
	const customSelectIcdx = ({ kode, diagnosa }) => {
		return `${kode} - ${diagnosa}`
	}
	let now = new Date()
	now.setDate(now.getDate()+14)
	const kunjungan_berikutnya = ref(now.toDateString())
	kunjungan_berikutnya.value = dayjs(kunjungan_berikutnya.value).format('DD/MM/YYYY') 

	// RESEP
	const { resep, resepResult, tambahResepKeRekamMedis, hapusDataResep } = useResepObat(data_resep_dan_obat, obat_select_value)	
	// END RESEP
	// TINDAKAN
	const { tindakan, tindakanResult, id_tindakan_ke_beautician, tambahTindakanKeRekamMedis, hapusDataTindakan } = useTindakan(tindakan_select_value)	
	// END TINDAKAN

	// ICDX
	const { icdx, icdxResult, kode_icdx, nama_icdx, tambahIcdxKeRekamMedis, hapusDataIcdx, changeDiagnosaUtama } = useIcdx(icdx_select_value)
	// END ICDX

	// BEAUTICIAN
	const { beautician, beauticianModel, tambahbeauticianKeRekamMedis, hapusDataBeautician, resetInputBeautician, beauticianDetail, lihatBeautician, beauticianResult } = useBeautician(tindakanResult, id_tindakan_ke_beautician)
	// END BEAUTICIAN

	// DOKTER
	const dataDokter = ref([])

	const getDokter = async () => {
		let response = await axios.get(`api/karyawan/getAllWhereJabatan/${user.value.cabang_id}/dokter`)

		if (response.data == 'kosong') {
			dataDokter.value = []
		} else {
			dataDokter.value = response.data
		}
	}
	// END DOKTER

	// POLI
	const dataPoli = ref([])
	const loading_poli = ref(true)
	const cek_data_poli = ref(false)

	const getPoli = async () => {
		let response = await axios.get(`api/poli/getWhereAktifRelationPoli/${user.value.cabang_id}`)

		if (response.data == 'kosong') {
			loading_poli.value = false
			cek_data_poli.value = true
			dataPoli.value = []
		} else {
			loading_poli.value = false
			cek_data_poli.value = false
			dataPoli.value = response.data
		}
	}
	// END POLI

	// LAYANAN
	const dataLayanan = ref([])
	const loading_layanan = ref(true)
	const cek_data_layanan = ref(false)

	const getLayanan = async () => {
		let response = await axios.get(`api/layanan/getWhereAktif`)

		if (response.data == 'kosong') {
			loading_layanan.value = false
			cek_data_layanan.value = true
			dataLayanan.value = []
		} else {
			loading_layanan.value = false
			cek_data_layanan.value = false
			dataLayanan.value = response.data
		}
	}
	// END LAYANAN

	const getTindakan = async () => {
		let response = await axios.get(`api/tindakan/get/${user.value.cabang_id}`)

		if (response.data == 'kosong') {
			tindakan.value = []
			cek_data_tindakan.value = true
		} else {
			tindakan.value = response.data
			cek_data_tindakan.value = false
		}
	}

	const getIcdx = async () => {
		let response = await axios.get(`api/icdx/get`);
		icdx.value = response.data
	}

	const memuat_icdx = ref(true)

	const getResep = async () => {
		let response = await axios.get(`api/resep_obat/get/${user.value.cabang_id}`)

		if (response.data == 'kosong') {
			resep.value = []
			memuat_icdx.value = false
		} else {
			resep.value = response.data
			memuat_icdx.value = false
		}
		getObat()
	}

	const obat = ref([])
	const loading_obat = ref(false)

	const getObat = async () => {
		loading_obat.value = true
		await axios.get(`api/stok_produk/getStokProdukWhereType/${user.value.cabang_id}/${type_obat.value}`)
		.then((response) => {
			if (response.data == 'kosong') {
				obat.value = []
			} else {
				obat.value = response.data
			}
		})
		.finally(() => {
			gabungkanDataResepDanObat()
			loading_obat.value = false
		})
		
	}


	const gabungkanDataResepDanObat = () => {
		data_resep_dan_obat.value = []
		for (var i = 0; i < resep.value.length; i++) {
			data_resep_dan_obat.value.push({
				"id" : resep.value[i].id,
				"kode" : resep.value[i].kode,
				'jenis' : 'resep',
				'type' : 'Umum',
				"nama" : resep.value[i].nama,
				"harga" : resep.value[i].harga,
				"stok" : resep.value[i].stok,
				"jumlah" : 1,
				"fee_dokter" : resep.value[i].fee_dokter 
			}) 
		}
		for (var j = 0; j < obat.value.length; j++) {
			data_resep_dan_obat.value.push({
				"id" : obat.value[j].produk_id,
				"kode" : obat.value[j].produk.kode,
				'jenis' : obat.value[j].produk.jenis,
				'type' : obat.value[j].produk.type,
				"nama" : obat.value[j].produk.nama,
				"harga" : obat.value[j].harga_jual ? obat.value[j].harga_jual : obat.value[j].produk.harga_jual_pasien,
				"stok" : obat.value[j].stok_akhir,
				"jumlah" : 1,
				"fee_dokter" : obat.value[j].produk.fee_dokter
			}) 
		}
		if (data_resep_dan_obat.value == null || data_resep_dan_obat.value == '') {
			cek_data_resep.value = true
		}
	}


	const getBeautician = async () => {
		let { data } = await axios.get(`api/karyawan/getAllWhereJabatan/${user.value.cabang_id}/asisten perawat`)

		if (data == 'kosong') {
			beautician.value = []
			cek_beautician.value = true
		} else {
			beautician.value = data
			cek_beautician.value = false
		}
	}


	// ---------End Get data tindakan & resep obat---------

	const rekam_medis = reactive({
		'id' : null,
		'cabang_id' : user.value.cabang_id,
		'kunjungan_id' : null,
		'nomor_rekam_medis' : null,
		'pasien_id' : 0,
		'dokter_id' : 0,
		'poli_id' : 0,
		'layanan_id' : 0,
		'resep' : [],
		'tindakan' : [],
		'nama_pasien' : null,
		'jenis_kelamin' : null,
		'keadaan_datang' : null,
		'dirujuk' : null,
		'kondisi' : null,
		'keterangan_pemeriksaan_umum' : null,
		'anamnesa' : '',
		'tinggi_badan': '', 
		'berat_badan': '', 
		'keadaan_umum': null, 
		'catatan_pemeriksaan_fisik': '', 
		'frekuensi_denyut_jantung': '', 
		'frekuensi_pernapasan': '', 
		'suhu_tubuh': '', 
		'tekanan_darah': '', 
		'saturasi_oksigen': '', 
		'kesadaran': '', 
		'kepala': '', 
		'thorax': '', 
		'abdomen': '', 
		'ekstremitas': '', 
		'eye_movement': '', 
		'refleks_motorik': '', 
		'verbal': '', 
		'kode_icdx': '', 
		'nama_icdx': '',
		'icdx': [],
		'diagnosa' : '', 
		'pemeriksaan_penunjang' : '', 
		'tindak_lanjut' : '', 
		'tindak_lanjut_detail' : '', 
		'foto_pasien': '',
		'informed_concent': ''
	})

	watch(() => rekam_medis.poli_id, (val) => changePoli(val))
	const namaPoliSelected = ref('')
	const changePoli = (poli_id) => {
		dataPoli.value.map((poli) => {
			poli.poli_id == poli_id ? namaPoliSelected.value = poli.nama : ''
		})
	}

	const getRekamMedis = async () => {
		loading_screen.value = true
		await axios.get(`api/rekam_medis/getWhereId/${rekam_medis_id}/${cabang.value.tipe}`)
		.then((response) => {

			rekam_medis.kunjungan_id = response.data.kunjungan_id 
			rekam_medis.id = response.data.id 
			rekam_medis.nomor_rekam_medis = response.data.nomor_rekam_medis 
			rekam_medis.pasien_id = response.data.pasien_id 
			rekam_medis.dokter_id = response.data.dokter_id 
			rekam_medis.poli_id = response.data.poli_id 
			rekam_medis.layanan_id = response.data.layanan_id 
			rekam_medis.resep = response.data.resep 
			rekam_medis.tindakan = response.data.tindakan 
			rekam_medis.nama_pasien = response.data.nama_pasien 
			rekam_medis.jenis_kelamin = response.data.jenis_kelamin 
			rekam_medis.keadaan_datang = response.data.keadaan_datang 
			rekam_medis.dirujuk = response.data.dirujuk 
			rekam_medis.kondisi = response.data.kondisi 
			rekam_medis.keterangan_pemeriksaan_umum = response.data.keterangan_pemeriksaan_umum 
			rekam_medis.anamnesa = response.data.anamnesa 
			rekam_medis.tinggi_badan = response.data.tinggi_badan  
			rekam_medis.berat_badan = response.data.berat_badan  
			rekam_medis.keadaan_umum = response.data.keadaan_umum  
			rekam_medis.catatan_pemeriksaan_fisik = response.data.catatan_pemeriksaan_fisik  
			rekam_medis.frekuensi_denyut_jantung = response.data.frekuensi_denyut_jantung  
			rekam_medis.frekuensi_pernapasan = response.data.frekuensi_pernapasan  
			rekam_medis.suhu_tubuh = response.data.suhu_tubuh  
			rekam_medis.tekanan_darah = response.data.tekanan_darah  
			rekam_medis.saturasi_oksigen = response.data.saturasi_oksigen  
			rekam_medis.kesadaran = response.data.kesadaran  
			rekam_medis.kepala = response.data.kepala  
			rekam_medis.thorax = response.data.thorax  
			rekam_medis.abdomen = response.data.abdomen  
			rekam_medis.ekstremitas = response.data.ekstremitas  
			rekam_medis.eye_movement = response.data.eye_movement  
			rekam_medis.refleks_motorik = response.data.refleks_motorik  
			rekam_medis.verbal = response.data.verbal  
			rekam_medis.kode_icdx = response.data.kode_icdx  
			rekam_medis.nama_icdx = response.data.nama_icdx 
			rekam_medis.icdx = response.data.icdx 
			rekam_medis.diagnosa = response.data.diagnosa  
			rekam_medis.pemeriksaan_penunjang = response.data.pemeriksaan_penunjang  
			rekam_medis.tindak_lanjut = response.data.tindak_lanjut  
			rekam_medis.tindak_lanjut_detail = response.data.tindak_lanjut_detail  
			rekam_medis.foto_pasien= response.data.foto_pasien 
			rekam_medis.informed_concent= response.data.informed_concent 

			rekam_medis.icdx.map((val) => {
				icdxResult.value.push({
					'id': val,
					'kode': val.kode,
					'nama': val.diagnosa,
					'diagnosa_utama': val.diagnosa_utama.toString(),
					'hapus' : true
				})
			})

			rekam_medis.resep.map((val) => {
				resepResult.value.push({
					'id': val,
					'resep_id' : val.resep_atau_obat_id,
					'jenis' : val.jenis,
					'type' : val.produk.type ?? null,
					'kode': val.produk.kode ?? null,
					'nama': val.nama,
					'harga': val.harga,
					'stok': val.stok.stok_akhir,
					'jumlah': val.jumlah,
					'fee_dokter' : val.fee_dokter,
					'signa' : val.signa,
					'hapus' : true
				})
				if(!signa.value.includes(val.signa)){
					signa.value.push(val.signa)
				}
			})
			
			rekam_medis.tindakan.map((val) => {
				let _beautician = []
				val.beautician_id.map((data, index) => {
					_beautician.push({
						id: data,
						nama_lengkap: val.nama_beautician[index],
						hapus: true
					})
				})

				tindakanResult.value.push({
					'id': val,
					'tindakan_id': val.tindakan_id,
					'kode': val.tindakan.kode_tindakan,
					'kategori': val.kategori,
					'nama': val.nama,
					'lama_tindakan': val.lama_tindakan,
					'jumlah': val.jumlah,
					'harga_pasien_member': val.harga_pasien_member,
					'beautician': _beautician,
					'fee_dokter': val.fee_dokter,
					'fee_beautician': val.fee_beautician,
					'fee_sales': val.fee_sales,
					'point': val.point,
					'hapus' : true
				})
			})


			let dataPasien = null
			dataPasien = response.data.pasien

			jenis_kelamin.value = dataPasien.jenis_kelamin 
			
			if (dataPasien.jenis_kelamin == 'L') {
				jenis_kelamin_pada_ui.value = 'Laki-laki'
			} else {
				jenis_kelamin_pada_ui.value = 'Perempuan'
			}

			umur.value = dataPasien.tanggal_lahir
			umur.value = get_age(umur.value)
			alergi.value = dataPasien.alergi
		})
		.finally(() => {
			loading_screen.value = false
		})
	}
	// TINDAKAN
	function useTindakan (tindakan_select_value) {
		const tindakan = ref([])
		const tindakanResult = ref([])
		const id_tindakan_ke_beautician = ref()

		function tambahTindakanKeRekamMedis (id) {
			let data = tindakan.value.filter((row) => {
				if (row.id == tindakan_select_value.value.id) {
					return row
				}
			})

			if (data[0]) {
				data = data[0]
				tindakan_select_value.value = ''

				let harga = 0
			if(data.harga_per_layanan) {
				harga = JSON.parse(data.harga_per_layanan).filter((val) => {
					if(val.layanan_id == rekam_medis.layanan_id) {
						return val
					}
				})
				harga = harga[0].harga
			} else {
				harga = data.harga_pasien_member
			}

				tindakanResult.value.push({
					'id': id,
					'tindakan_id': data.id,
					'kode': data.kode_tindakan,
					'kategori': data.kategori,
					'nama': data.nama,
					'lama_tindakan': data.lama_tindakan,
					'jumlah': 1,
					'harga_pasien_member': harga,
					'beautician': [],
					'fee_dokter': data.fee_dokter,
					'fee_beautician': data.fee_beautician,
					'fee_sales': data.fee_sales,
					'point': data.point,
					'hapus' : true
				})
			}
			
		}

		function hapusDataTindakan (index) {
			console.log(index)
			for(let j = 0; j < tindakanResult.value.length; j++) {
				if (tindakanResult.value[j].id == index) {
					tindakanResult.value.splice(j, 1)
				}
			}
		}

		return {
			tindakan,
			tindakanResult,
			tambahTindakanKeRekamMedis,
			hapusDataTindakan,
			id_tindakan_ke_beautician
		}
	}
	// END TINDAKAN

	// Proses Rekam Medis
	const prosesRekamMedis =   _debounce( async() => {
		if (loading.value == false) {
			let beautician_kosong = ref(false)
			// console.log(tindakanResult)
			if (tindakanResult.value.length > 0) {
				for (var i = 0; i < tindakanResult.value.length; i++) {
					if (tindakanResult.value[i].beautician == null || tindakanResult.value[i].beautician == '') {
						beautician_kosong.value = true
					}
				}	
			}
			

			if (beautician_kosong.value == true) {
				Swal.fire({
					text: 'Tambahkan Perawat',
					icon: 'error',
					showConfirmButton: false,
					timer: 2000
				})
			} else {
				loading.value = true

				rekam_medis.resep 	  = resepResult.value
				rekam_medis.tindakan  = tindakanResult.value
				rekam_medis.icdx 	  = icdxResult.value
				rekam_medis.kode_icdx = kode_icdx.value
				rekam_medis.nama_icdx = nama_icdx.value
				axios.put(`api/rekam_medis/update/${rekam_medis.id}`, rekam_medis)
				.then((response) => {
					Swal.fire({
						title: 'Berhasil!',
						text: 'Berhasil Menambahkan Data',
						icon: 'success',
						showConfirmButton: false,
						timer: 1500
					})	
					console.log(response)
					loading.value = false
					router.replace('/perawatan')
				})
				.catch((error) => {
					Swal.fire({
						title: 'Gagal!',
						text: 'Gagal menambahkan data',
						icon: 'error',
						showConfirmButton: false,
						timer: 1500
					})
					loading.value = false
					console.log(error)
				})	
			}	
		}
	}, 100)
	// End Proses Rekam Medis

	// File Handle
	const after_foto = ref(null)
	const after_foto_dom = ref('')
	const inputImage = (e) => {
		let files = e.target.files || e.dataTransfer.files
		if(files[0].type == 'image/png' || files[0].type == 'image/jpeg') {
			if (files[0].size > 3000000) {
				rekam_medis.foto_pasien = ''
				after_foto_dom.value.value = ''
				after_foto.value = null
				ElMessage({
					message: 'Ukuran file harus kurang dari 2MB.',
					type: 'warning',
				})
			} else {
				rekam_medis.foto_pasien = files[0]
				createImage(files[0]);
			}
		}  else {
			rekam_medis.foto_pasien = ''
			after_foto_dom.value.value = ''
			after_foto.value = null
			ElMessage({
				message: 'Format file harus png atau jpeg.',
				type: 'warning',
			})
		}
	}

	const createImage = (file) => {
		let reader = new FileReader();

		reader.onload = (e) => {
			after_foto.value = e.target.result;
		}

		if (file) reader.readAsDataURL(file) 
		
	}

	const informed_concent_dom = ref('')
	const inputInformedConcent = (e) => {
		let files = e.target.files || e.dataTransfer.files
		if(files[0].type == 'image/png' || files[0].type == 'application/pdf' || files[0].type == 'image/jpeg') {
			if (files[0].size > 2000000) {
				rekam_medis.informed_concent = ''
				informed_concent_dom.value.value = ''
				ElMessage({
					message: 'Ukuran file harus kurang dari 2MB.',
					type: 'warning',
				})
			} else {
				rekam_medis.informed_concent = files[0]
			}
		}  else {
			rekam_medis.informed_concent = ''
			informed_concent_dom.value.value = ''
			ElMessage({
				message: 'Format file harus pdf, png atau jpeg.',
				type: 'warning',
			})
		}
	}
	// End File Handle

	// Signa
	const signa = ref([
		"3 x 0,5 p.c",
		"3 x 1 p.c",
		"3 x 1,5 p.c",
		"3 x 2 p.c",
		"3 x 0,5 a.c",
		"3 x 1 a.c",
		"3 x 1,5 a.c",
		"3 x 2 a.c",
		"2 x 0,5 p.c",
		"2 x 1 p.c",
		"2 x 1,5 p.c",
		"2 x 2 p.c",
		"2 x 0,5 a.c",
		"2 x 1 a.c",
		"2 x 1,5 a.c",
		"2 x 2 a.c",
		"1 x 0,5 p.c",
		"1 x 1 p.c",
		"1 x 1,5 p.c",
		"1 x 2 p.c",
		"1 x 0,5 a.c",
		"1 x 1 a.c",
		"1 x 1,5 a.c",
		"1 x 2 a.c",
	])
	const newSigna 	  = ref(null)
	const addNewSigna = () => {
		if(newSigna.value) {
			signa.value.push(newSigna.value)
			newSigna.value = null
		}
	}
	// End Signa

	onMounted(() => {
		getResep()
		getTindakan()
		getIcdx()
		getBeautician()
		getDokter()
		getPoli()
		getLayanan()
		getRekamMedis()
	})



	function useBeautician (tindakanResult, id_tindakan_ke_beautician) {
		const beautician = ref([])
		const beauticianModel = ref()
		let dataBeautician = reactive({
			'id': '',
			'nama': '',
			'hapus' : false
		})
		const beauticianResult = ref([])
		const beauticianDetail = ref([])

		function tambahbeauticianKeRekamMedis () {

			let data = beautician.value[beauticianModel.value]
			let id_tindakan = id_tindakan_ke_beautician.value
			dataBeautician.id = data.id
			dataBeautician.nama_lengkap = data.nama_lengkap
			dataBeautician.hapus = true

			tindakanResult.value[id_tindakan].beautician.push(dataBeautician)
			beauticianResult.value = tindakanResult.value[id_tindakan].beautician

			dataBeautician = {}
		}

		function lihatBeautician (index) {
			beauticianDetail.value = tindakanResult.value[index].beautician
		}

		function hapusDataBeautician (index) {
			for(let j = 0; j < beauticianResult.value.length; j++) {
				if (beauticianResult.value[j].id == index) {
					tindakanResult.value[id_tindakan_ke_beautician.value].beautician.splice(j, 1)
					beauticianResult.value = tindakanResult.value[id_tindakan_ke_beautician.value].beautician
				}
			}

			if (beauticianResult.value.length == 0) {
				tindakanResult.value[id_tindakan_ke_beautician.value].beautician = []
				beauticianResult.value = tindakanResult.value[id_tindakan_ke_beautician.value].beautician
				beauticianModel.value = ''
			}
		}

		function resetInputBeautician () {
			beauticianModel.value = ''
			beauticianResult.value = []
		}

		return {
			beautician,
			beauticianModel,
			tambahbeauticianKeRekamMedis,
			hapusDataBeautician,
			resetInputBeautician,
			beauticianDetail,
			lihatBeautician,
			beauticianResult
		}
	}


	function useResepObat (data_resep_dan_obat, obat_select_value) {
		const resep = ref([])
		const resepResult = ref([])	


		function tambahResepKeRekamMedis (id) {
			let data = data_resep_dan_obat.value.filter(row => {
				if (row.id == obat_select_value.value.id && row.kode == obat_select_value.value.kode) {
					return row
				}
			})

			if (data[0]) {
				data = data[0]
				obat_select_value.value = ''
				resepResult.value.push({
					'id': id,
					'resep_id' : data.id,
					'jenis' : data.jenis,
					'type' : data.type,
					'kode': data.kode,
					'nama': data.nama,
					'harga': data.harga,
					'stok': data.stok,
					'jumlah': 1,
					'fee_dokter' : data.fee_dokter,
					'signa' : '',
					'hapus' : true
				})

			}
		}

		function hapusDataResep (index) {
			for(let j = 0; j < resepResult.value.length; j++) {
				if (resepResult.value[j].id == index) {
					resepResult.value.splice(j, 1)
				}
			}

		}

		return {
			resep,
			resepResult,
			tambahResepKeRekamMedis,
			hapusDataResep
		}
	}


	function useIcdx (icdx_select_value) {
		const icdx = ref([])
		const icdxResult = ref([])
		const kode_icdx = ref([])
		const nama_icdx = ref([])	

		function tambahIcdxKeRekamMedis (id) {
			let data = icdx.value.filter((row) => {
				if (row.kode == icdx_select_value.value.kode) {
					return row
				}
			})

			if (data[0]) {
				data = data[0]

				icdxResult.value.push({
					'id': id,
					'kode': data.kode,
					'nama': data.diagnosa,
					'diagnosa_utama': "0",
					'hapus' : true
				})

				kode_icdx.value.push(data.kode)
				nama_icdx.value.push(data.diagnosa)	

				icdx_select_value.value = ''
			}
		}

		function hapusDataIcdx (index) {
			for(let j = 0; j < icdxResult.value.length; j++) {
				if (icdxResult.value[j].kode == index) {
					icdxResult.value.splice(j, 1)

					kode_icdx.value.splice(j, 1)
					nama_icdx.value.splice(j, 1)
				}
			}
		}

		function changeDiagnosaUtama (index) {
			icdxResult.value.map((val, i) => {
				if(i != index) {
					val.diagnosa_utama = "0"
				}
			})
		}

		return {
			icdx,
			icdxResult,
			tambahIcdxKeRekamMedis,
			hapusDataIcdx,
			kode_icdx, 
			nama_icdx,
			changeDiagnosaUtama
		}
	}
</script>

<style>
img {
width: 30%;
display: flex;
margin-bottom: 10px;
margin-top: 20px;
}
</style>